import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Redirect, useLocation } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import successIcon from "../../assets/icons/finish-icon.png";
import logo from "../../assets/icons/logo.png";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "white",
    padding: "20px",
    height: "100vh",
    fontFamily: "'Poppins', sansSerif",
  },
  inlineCenter: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "10px 0px",
  },
  buttonNext: {
    color: "#fff",
    textTransform: "none",
    fontSize: "12px",
    backgroundColor: "#2761BA",
    border: "1px solid #F4991E",
    borderRadius: "5px",
    padding: "10px 25px",
    display: "flex",
    alignItems: "center",
    fontFamily: "'Poppins', sansSerif",
    "&:hover": {
      backgroundColor: "#2761BA",
      color: "#fff",
    },
  },
  containerResend: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "90%",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  containerResendText: {
    marginBottom: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  resendTitle: {
    fontSize: "28px",
    fontWeight: "600",
    margin: "10px 0",
    color: "#2761BA",
  },
  resendText: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400",
  },
}));

const Finish = () => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_IS_TRACK === "true") {
      ReactGA.pageview("/finish-assessment");
    }
  }, []);

  const backHandler = () => {
    setRedirect({ pathname: "/" });
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className={classes.content}>
        <a href="https://coding.id/">
          <img src={logo} alt="logo" />
        </a>
        <div className={classes.containerResend}>
          <img
            src={successIcon}
            alt="Loading"
            style={{ height: "200px", width: "200px" }}
          />{" "}
          <br />
          <h2 className={classes.resendTitle}>Congratulations!</h2>
          <div className={classes.containerResendText}>
            <p className={classes.resendText}>
              You’ve submitted assessment, good luck!
            </p>
            {/* <Button
              variant="contained"
              onClick={backHandler}
              size="small"
              className={classes.buttonNext}
            >
              <HomeIcon fontSize="small" style={{ marginRight: "5px" }} />
              Back to Home
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Finish;
