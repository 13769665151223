import React, { useContext, useEffect } from "react";
import NewCompiler from "../views/newCompiler/NewCompiler";
import Compiler from "../views/compiler/Compiler";
import Finish from "../views/finish/Finish";
import NewLogin from "../views/login/NewLogin";
import NotFound from "../views/notFound/NotFound";
import Auth from "../views/auth/Auth";
import TimesUp from "../views/timesUp/TimesUp";
import NotVerified from "../views/notVerified/NotVerified";
import TourGuide from "../views/tourGuide/TourGuide";
import { userContext } from "../context/AuthContextProvider";
import AuthProvider from "../context/AuthContextProvider";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";

import ReactGA from "react-ga";
const TRACKING_ID = "UA-202737438-1"; // OUR_TRACKING_ID

if (process.env.REACT_APP_IS_TRACK === "true") {
  ReactGA.initialize(TRACKING_ID, { debug: false });
}
//ReactGA.pageview(window.location.pathname + window.location.search);

function PrivateRoute({ children, ...rest }) {
  const context = useContext(userContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("auth") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const CompilerRoutes = () => {
  // const Auth = React.useContext(AplicantContext);
  return (
    <Router>
      <AuthProvider>
        <Route exact path="/" component={NotFound} />
        <Route exact path="/code=:code" component={Auth} />
        <Route exact path="/timesup" component={TimesUp} />
        <Route exact path="/notverified" component={NotVerified} />
        <Route exact path="/finish" component={Finish} />
        <Route exact path="/guide" component={TourGuide} />
        <PrivateRoute exac path="/test">
          <Compiler />
        </PrivateRoute>
      </AuthProvider>
    </Router>
  );
};

export default CompilerRoutes;
