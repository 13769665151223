import React, { useState } from "react";
import { Button } from "@material-ui/core";

import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { makeStyles } from "@material-ui/core/styles";

// icons
import InstructionIcon from "@material-ui/icons/Assignment";
import CodeIcon from "@material-ui/icons/Code";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

import Countdown, { zeroPad } from "react-countdown";
import Swal from "sweetalert2";
import logo from "../../assets/icons/logo.png";
import { Redirect } from "react-router-dom";

import UserInput from "../../components/userInput/UserInputGuide";
import Instruction from "../../components/instruction/InstructionGuide";
import Score from "../../components/score/ScoreGuide";
import Result from "../../components/result/ResultGuide";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "5px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  root: {
    height: "100vh",
    width: "100vw",
    fontFamily: "'Poppins', sansSerif",
  },
  navbar: {
    backgroundColor: "#F5F8FF",
    height: "10vh",
    padding: "10px",
    border: "1px solid #CFD6E5",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  questionTitle: {
    margin: "0px auto",
    fontWeight: "bold",
    color: "#2B2E33",
  },
  countdown: {
    fontWeight: "bold",
    padding: "5px",
    color: "#234B88",
  },
  containerAssessment: {
    height: "90vh",
    width: "100vw",
    display: "flex",
    justifyContent: "space-between",
  },
  containerSoal: {
    width: "50vw",
    fontWeight: "600",
    border: "1px solid #CFD6E5",
  },
  containerTab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "10px",
    borderBottom: "1px solid #CFD6E5",
  },
  tab: {
    "&:hover": {
      cursor: "pointer",
      color: "#234B88",
    },
  },
  tabActive: {
    backgroundColor: "#CFD6E5",
    padding: "5px 15px",
    borderRadius: "5px",
  },
  textTabActive: {
    color: "#2761BA",
    fontSize: "15px",
  },
  textTabInactive: {
    color: "#6C7280",
  },
  containerNavigation: {
    padding: "20px",
  },
}));

const step = [
  {
    content: (
      <div>Pilih pertanyaan untuk dikerjakan sesuai dengan instruksi</div>
    ),
    placement: "bottom",
    target: ".question-guide",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    title: "Choose Question",
  },
  {
    content: <div>Pilih bahasa pemrograman sesuai kemampuan diri kamu</div>,
    placement: "bottom",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".language-guide",
    title: "Choose Language",
  },
  {
    content: <div>Tempat untuk menulis code kamu</div>,
    placement: "right",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".editor-guide",
    title: "Text Editor",
  },
  {
    content: <div>Reset ulang Code yang ditulis di text editor</div>,
    placement: "top",
    disableOverlayClose: true,
    target: ".reset-guide",
    title: "Reset Code",
  },
  {
    content: <div>Menjalankan codingan yang sudah ditulis di text editor</div>,
    placement: "top",
    disableOverlayClose: true,
    target: ".run-guide",
    title: "Run Code",
  },
  {
    content: <div>Berisi instruksi pengerjaan soal</div>,
    placement: "left",
    disableOverlayClose: true,
    spotlightClicks: true,
    target: ".instruction-guide",
    title: "Instruction",
  },
  {
    content: (
      <div>
        Data yang telah ditentukan oleh pihak Coding ID untuk digunakan dalam
        testing.
      </div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".sample-input-guide",
    title: "Sample Input",
  },
  {
    content: <div>Output Data yang berasal dari code yang kamu tulis</div>,
    placement: "left",
    disableOverlayClose: true,
    target: ".your-output-guide",
    title: "Your Output",
  },
  {
    content: <div>Output data yang diharapkan muncul</div>,
    placement: "left",
    disableOverlayClose: true,
    target: ".expected-output-guide",
    title: "Expected Output",
  },
  {
    content: (
      <div>
        Menjalankan validasi code untuk menentukan apakah code kamu sudah
        berfungsi dengan baik
      </div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".test-case-guide",
    title: "Test Case",
  },
  {
    content: (
      <div>Rangkuman nilai yang kamu dapat selama pengerjaan assessment</div>
    ),
    placement: "left",
    disableOverlayClose: true,
    target: ".score-guide",
    title: "Score",
  },
  {
    content: <div>Batasan waktu pengerjaan</div>,
    placement: "bottom",
    disableOverlayClose: true,
    target: ".countdown-guide",
    title: "Timer",
  },
  {
    content: (
      <div>Klik button submit setelah selesai mengerjakan semua soal</div>
    ),
    placement: "bottom",
    disableOverlayClose: true,
    target: ".submit-guide",
    title: "Submit Task",
  },
];

const TourGuide = () => {
  const classes = useStyles();
  const [run, setRun] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(Date.now() + 7200 * 60000);
  const [steps, setSteps] = useState(step);
  const [tabActive, setTabActive] = useState("instruction");
  const [redirect, setRedirect] = useState(null);

  const rendererTime = ({ days, hours, minutes, seconds, completed }) => {
    if (days == 0 && hours == 0 && minutes == 5 && seconds == "00") {
      Swal.fire("Times almost up", "Please check again your answer", "info");
    }
    if (completed) {
      return <span className={classes.countdown}>Times Up!</span>;
    } else {
      if (days > 0) {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(days * 24 + hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      } else {
        return (
          <span className={classes.countdown}>
            Remaining Time = {zeroPad(hours)}:{zeroPad(minutes)}:
            {zeroPad(seconds)}
          </span>
        );
      }
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

      let step = index + (action === ACTIONS.PREV ? -1 : 1);
      if (action == "next") {
        if (step == 6) {
          setTabActive("result");
        } else if (step == 10) {
          setTabActive("score");
        }
      } else if (action == "prev") {
        if (step == 9) {
          setTabActive("result");
          step = 6;
        } else if (step == 5) {
          setTabActive("instruction");
        }
      }
      setStepIndex(step);
      // this.setState({
      //     stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      // });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      window.location.replace("https://coding.id/dashboard");
    }
  };

  const renderTab = () => {
    if (tabActive == "instruction") {
      return <Instruction />;
    } else if (tabActive == "result") {
      return <Result />;
    } else if (tabActive == "score") {
      return <Score />;
    }
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  return (
    <div className={classes.root}>
      {renderRedirect()}
      <Joyride
        callback={handleJoyrideCallback}
        isLastStep={true}
        run={run}
        steps={steps}
        continuous
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        styles={{
          buttonNext: { background: "#3f51b5" },
          options: { primaryColor: "#3f51b5" },
          buttonSkip: {
            background: "#f74747",
            color: "#fff",
            borderRadius: "5px",
          },
        }}
      />
      <div className={classes.root}>
        <div className={classes.navbar}>
          <img src={logo} alt="" />
          <p className={classes.questionTitle}>TEST001 - PSBB</p>
          <div className="countdown-guide">
            <Countdown
              date={timeLeft}
              renderer={rendererTime}
              daysInHours={true}
            />
          </div>
        </div>
        <div className={classes.containerAssessment}>
          <UserInput />
          <div className={classes.containerSoal}>
            <div className={classes.containerTab}>
              <div
                className={`${classes.tab} ${
                  tabActive == "instruction" ? classes.tabActive : ""
                }
                            `}
              >
                <span
                  className={`${
                    tabActive == "instruction"
                      ? classes.textTabActive
                      : classes.textTabInactive
                  }`}
                >
                  <InstructionIcon
                    fontSize="small"
                    style={{ marginRight: "10px" }}
                  />
                  Instructions
                </span>
              </div>
              <div
                className={`${classes.tab} ${
                  tabActive == "result" ? classes.tabActive : ""
                }
                            `}
              >
                <span
                  className={`${
                    tabActive == "result"
                      ? classes.textTabActive
                      : classes.textTabInactive
                  }`}
                >
                  <CodeIcon fontSize="small" style={{ marginRight: "10px" }} />
                  Result
                </span>
              </div>
              <div
                className={`${classes.tab} ${
                  tabActive == "score" ? classes.tabActive : ""
                }
                            `}
              >
                <span
                  className={`${
                    tabActive == "score"
                      ? classes.textTabActive
                      : classes.textTabInactive
                  }`}
                >
                  <AssignmentTurnedInIcon
                    fontSize="small"
                    style={{ marginRight: "10px" }}
                  />
                  Score
                </span>
              </div>
              <div className="submit-guide">
                <Button variant="contained" color="primary">
                  Submit
                </Button>
              </div>
            </div>
            <div className={classes.containerNavigation}>{renderTab()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourGuide;
