import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import Axios from "axios";

// loading
import Lottie from "react-lottie";
import * as loadingAnimation from "./23888-website-build.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation.default,
  speed: "1.5",
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  backgroundColor: "#13335f",
};

const useStyles = makeStyles({
  containerLogin: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textAuth: {
    fontFamily: "'Poppins', sansSerif",
    marginTop: "-20px",
    fontSize: "16px",
  },
});

const Auth = (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDataAssessment();
  }, []);

  const getDataAssessment = () => {
    let code = props.match.params.code;

    Axios.get(
      `${process.env.REACT_APP_API_END_POINT}/assessment/taketask/${code}`
    )
      .then((res) => {
        localStorage.setItem("auth", true);
        setRedirect({
          pathname: "/test",
          state: {
            dataAssessment: res.data.assessment,
            listQuestion: res.data.assessment.list_question,
            dataMember: res.data.member,
            timer: res.data.timer,
            startTest: Date.now(),
          },
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        if (err.response.data.message == "Times up") {
          setRedirect({ pathname: "/timesup" });
        } else {
          setRedirect({ pathname: "/notverified" });
        }
      });
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className={classes.containerLogin}>
        <Lottie
          options={defaultOptions}
          isClickToPauseDisabled={true}
          height={300}
          width={400}
        />
        <span className={classes.textAuth}>
          Oasys mu sedang di generate.. Mohon tunggu sebentar yak..
        </span>
      </div>
    </>
  );
};

export default Auth;
